"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Should load before everything else
require("./lib/exports");
// Include everything except forms and the separate scripts
require("./lib/accordion");
require("./lib/ajax-components");
require("./lib/arrow-graphic");
require("./lib/audio-pill");
require("./lib/collapse-list");
require("./lib/email-links");
require("./lib/exit-links");
require("./lib/expandable-text");
require("./lib/facet-search");
require("./lib/footer");
require("./lib/fs-return-link");
require("./lib/gallery");
require("./lib/goal-tracking");
require("./lib/language-dropdown");
require("./lib/left-nav");
require("./lib/line-clamp");
require("./lib/live-chat");
require("./lib/on-this-page");
require("./lib/popups");
require("./lib/search-results");
require("./lib/site-nav");
require("./lib/site-search");
require("./lib/slider");
require("./lib/subscribe-panel-blog");
require("./lib/subscribe");
require("./lib/tables");
require("./lib/tab-sections");
require("./lib/tabnav");
require("./lib/tabs");
require("./lib/usa-banner");
require("./lib/youtube-embeds");
// NIDDK form implementations (.dk-form)
require("./lib/gfr-calc-legacy"); // Legacy GFR calculator
require("./lib/forms"); // Load after other form scripts
